#write-recette h3, #write-recette h4, #write-recette p, #write-recette ul, #write-blog h3, #write-blog h4, #write-blog p, #write-blog ul {
  margin-left: 15px;
}

#h2_recette, #h2_blog {
  margin-top: 100px;
  text-align: center;
  font-size: 32px;
}

#recettes_list, #post-list {
  width: 100%;
  margin: auto;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  min-height: 80vh;
}

#ingredient_list input {
  border: none;
  padding: 2px;
  font-family: "Cabin";
  font-size: 18px;
  margin: 3px 0;
  border-radius: 5px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.10);
}

.recette_step textarea {
  border: none;
  resize: none;
  border-radius: 5px;
  margin-left: 15px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.10);
  width: 95%;
  font-family: "Cabin";
  font-size: 18px;
  padding: 5px 0 0 5px;
  outline: none;
}


.recette-item {
  width: 30%;
  height: 400px;
  overflow: hidden;
  border-radius: 15px;
  margin: 10px;
  position: relative;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  cursor: pointer;
}

.recette-item:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

.recette-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
}

.recette-item .recette-content {
  background: #F0F0F0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 95px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: max-content;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.recette-item .recette-content h3 {
  text-align: center;
  /* margin-top: 10px; */
}

.recette-content .recette-categories {
  display: flex;
  width: 100%;
  margin-left: 30px;
}

.recette-content .recette-category {
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

#selected-image, #write-recette img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

#write-recette, #write-blog {
  max-width: 1000px;
  margin: auto;
  min-height: 80vh;
  margin-bottom: 30px;
}

#recette-image_button, #blog-image_button {
  background: #efab45;
  margin: 10px auto;
  padding: 10px 30px;
  border-radius: 10px;
  display: block;
  width: 150px;
  text-align: center;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
}


#write-recette h3 {
  font-size: 28px;
  margin-top: 15px;
}

#write-recette h4 {
  font-size: 22px;
  margin-top: 10px;
}

#write-recette li, #write-recette p {
  font-size: 18px;
}

#write-recette form {
  display: flex;
  align-items: flex-end;
}

#write-recette form button {
  width: min-content;
  font-size: 28px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

#add-recette-button, #add-post-button {
  display: flex;
  justify-content: flex-end;
}

#add-recette-button > div, #add-post-button > div {
  background: #efab45;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.2s;
}

#add-recette-button > div:hover, #add-post-button > div:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

#input_ingredient, #input_step, #recette_title_input, #blog_title_input {
  margin: 10px 0;
  border: none;
  background: none;
  outline: none;
  font-size: 18px;
  font-family: "Cabin";
  padding: 5px;
  border-bottom: 2px #efab45 solid;
  width: 300px;
}

#input_step {
  width: 100%;
}

@media screen and (max-width: 768px) {
  #recettes_list {
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }

  .recette-item {
    width: 90%;
  }

  #write-recette img, #write-blog img {
    width: 100vw;
    height: 300px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
  }
 
  .recette_step textarea {
    width: 90%;
  }

  #add-recette-button {
    margin-right: 10px;
  }
}