body {
  margin: 0;
  padding: 0;
  font-family: "Cabin", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* 404 error */
#error {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#error img {
  width: 20%;
  min-width: 150px;
  margin-bottom: 30px;
  margin-top: -59px;
  max-width: 300px;
}

#error h1 {
  font-size: 36px;
  text-align: center;
}




/* Articles */
#article {
  margin: 100px auto;
  max-width: 1000px;
}

#article ul {
  list-style:disc
}

@media screen and (max-width: 767px) {
  #article {
    margin: 80px 20px;
  }
}



/* Navbar */
nav {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 80px;
  background: rgba(240,240,240,0);
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
}

nav img {
  height: 60px;
  float: left;
  margin: 10px 0 0 10px;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

nav li {
  margin: 0 20px;
  font-size: 20px;
}

nav a {
  color: black;
  text-decoration: none;
  transition: all 0.2s;
}

nav a:visited, #menu-xs a {
  color: black;
}

nav a:hover {
  text-decoration: underline;
}

nav p {
  cursor: pointer
}

nav p:hover {
  text-decoration: underline;
}

#service_menu {
  position: absolute;
  background: #F0F0F0;
  margin-top: 28px;
  box-shadow: 0px 7px 6px 2px rgb(0 0 0 / 25%);
  border-radius: 0 0 10px 10px;
}

#service_menu ul {
  height: auto;
  padding: 0 30px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#service_menu li {
  margin: 5px 0;
  text-align: left;
  width: 100%;
}

#menu-icon {display: none}


/* Homepage */
#homepage, #homepage_body {
  height: 100vh;
  background-color: #EFAB45;
}

#homepage_body {
  /* max-width: 1380px; */
  display: flex;
  /* justify-content: center; */
}

#homepage_left {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F0F0F0;
  padding: 0 100px 0 100px;
  border-bottom-right-radius: 80%;
  border-top-right-radius: 25%;
}

#homepage h1 {
  font-size: 54px;
  font-weight: bold;
  text-align: left;
}

#homepage h2 {
  font-size: 32px;
  max-width: 80%;
  margin: 15px 0;
}

#homepage li {
  font-size: 20px;
}

#homepage #homepage_bar {
  background-color: #C4C4C4;
  width: 5px;
  height: 100%;
  float: left;
  margin-right: 10px;
}

#homepage_right img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  min-width: 540px;
  height: auto;
}

/* Presentation */

#presentation {
  background: #F0F0F0;
  padding: 75px 0;
}

#main_presentation {
  max-width: 1000px;
  margin: auto;
  display: flex;
  padding: 0 20px;
}

#main_presentation img {
  max-width: 395px;
  width: 40%;
  height: 100%;
  margin-right: 30px;
}

#main_presentation h3 {
  font-size: 36px;
  margin-bottom: 5px;
}

#main_presentation p {
  font-size: 20px;
}


/* presentation compétitions */
#competitions_presentation {
  max-width: 1000px;
  margin: auto;
  padding-top: 50px;
}

#competitions_presentation h3 {
  font-size: 36px;
  margin-bottom: 15px;
  text-align: center;
}

#competition_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.competition_list_item {
  width: 22%;
}

.competition_list_item img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.competition_list_item:first-child img, .competition_list_item:last-child img {
  height: 350px;
  margin-top: -50px;
}

.competition_list_item p {
  text-align: center;
}


/* Services */
#services {
  background: #EFAB45;
  padding: 50px 20px;
}

#services h3 {
  font-size: 36px;
  text-align: center;
}

#services h4 {
  font-size: 24px;
  max-width: 60%;
  text-align: center;
  margin: 0 auto 20px;
}

#services_list {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.services_list_item {
  width: 40%;
  background-color: #F0F0F0;
  height: 140px;
  display: flex;
  align-items: center;
  margin: 20px;
  padding: 0 20px;
  border-radius: 15px;
  transition: all 0.2s;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
}

.services_list_item img:first-child {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.services_list_item img:last-child {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.services_list_item img:last-child:hover {
  cursor: pointer;
}

.services_list_item h5 {
  font-weight: 600;
  font-size: 22px
}

.services_list_item div {
  height: 100%;
  padding-top: 40px
}

.services_list_item:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}


/* Photos coaching */
#photos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1000px;
  padding: 20px;
}

#photos img {
  width: 28%;
  height: 300px;
  object-fit: cover;
  margin: 20px;
}


/* avis */
#avis {
  background-color: #EFAB45;
  padding: 20px;
}

#avis h3 {
  font-size: 36px;
  text-align: center;
}

#avis_totaux {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #F0F0F0;
  font-size: 20px;
  margin: 10px 0;
}

#avis_totaux img {
  width: 30px;
  height: 30px;
  margin-right: 7px;
}

#avis_liste {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  max-width: 1200px;
  margin: 0 auto 20px;
}

.avis_item {
  background-color: #F0F0F0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  width: 20%;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  margin: 10px;
}

.avis_item > img:first-child {
  width: 100px;
  height: 100px;
}

.avis_item p {
  margin: 5px 0;
}

.avis_date {
  font-size: 12px;
}

.avis_item_reward img {
  width: 30px;
  height: 30px;
  margin: 0 2px;
}

.avis_content {
  width: 90%;
}

/* Contact */
#contact {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 40px;
}

#contact #contact_coords {
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#contact #contact_coords img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

#contact #contact_coords > div {
  display: flex;
  font-size: 18px;
  margin: 5px 10px;
}

#contact h3 {
  font-size: 36px;
  text-align: center;
  margin-top: 20px
}

#contact input, #contact textarea {
  background-color: #E2E2E2;
  border: none;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin: 10px;
  font-family: 'Cabin', sans-serif;
  transition: all 0.2s;
}

#contact textarea {
  width: 100%;
  width: -webkit-fill-available;
  width: moz-available;
  resize: vertical;
}

#contact input {
  width: 30%;
}

#contact #contact_infos {
  display: flex;
  justify-content: space-between;
}

#send_button {
  width: 100%;
  display: flex;
  justify-content: right;
}

#button {
  margin: 10px;
  margin-top: 0;
  background-color: #EFAB45;
  border-radius: 10px;
  padding: 10px 30px;
  font-size: 20px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s;
}

#button:hover, #contact input:hover, #contact textarea:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

/* Footer */
footer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #575757;
  color: white;
  padding: 20px;
}

footer a {
  color: white;
}


/* Services */
.yellow-divider {
  height: 2px; 
  width: 30%;
  background: #EFAB45;
  margin: 15px auto;
}

.overlay {
  background:rgba(255, 255, 255, 0.22);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#services_suivi-alimentaire, #tarifs_suivi-alimentaire {
  background-image: url("./Images/Background/suivi\ alimentaire.webp");
  background-position: center;
  background-size: cover;
}

#services_suivi-musculation, #tarifs_suivi-musculation {
  background-image: url("./Images/Background/suivi musculation.webp");
  background-position: center;
  background-size: cover;
}

#services_coaching-en-salle, #tarifs_coaching-en-salle {
  background-image: url("./Images/Background/coaching en salle.webp");
  background-position: center;
  background-size: cover;
}

#services_coaching-a-domicile, #tarifs_coaching-a-domicile {
  background-image: url("./Images/Background/coaching domicile.webp");
  background-position: center;
  background-size: cover;
}

.main-content {
  background: white;
  width: 60%;
  max-width: 1000px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 10px;
  margin: 100px 0;
}

.main-content .service-icon {
  width: 100px;
  height: auto;
}

.main-content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.main-content-header h2 {
  margin-top: 5px;
}

.main-content-body {
  display: flex;
  flex-direction: column;
}

.main-content-body img {
  width: 60px;
}

.main-content-body__row {
  display: flex;
}

.main-content-body__row > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.main-content-body h3 {
  margin: 10px 0 0;
  max-width: 70%;
  font-weight: normal;
}

.tarif-button {
  background: #EFAB45;
  margin: 25px auto 15px;
  padding: 10px 30px;
  font-size: 22px;
  border-radius: 15px;
  width: fit-content;
  font-weight: bold;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  cursor: pointer;
}

.tarif-button:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

.disable-on-lg {
  display: none;
}


/* tarifs */
.tarif-box {
  background-color: #F0F0F0;
  border-radius: 10px;
}

.tarif-box .popular {
  background-color: #EFAB45;
  width: min-content;
  font-size: 20px;
  padding: 5px 15px;
  font-weight: bold;
  margin: -40px auto 6px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
}

.overlay-tarifs {
  background:rgba(255, 255, 255, 0.22);
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.tarifs-list {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
}

.tarif-box {
  margin: 30px 10px;
  padding: 25px 0 0;
  width: 20%;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.tarif-box h3{
  font-size: 24px;
}

.tarif-box h4 {
  font-size: 62px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.devise {
  font-size: 22px;
}

.select-button {
  background-color: #EFAB45;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  margin: 10px 30px;
}

.select-button:hover {
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

.tarif-box p {
  margin: 15px 5px; 
}


.tarif-footer p {
  margin: 25px 10px;
}



/* Payments */
.overlay-payment {
  background:rgba(255, 255, 255, 0.22);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.overlay-payment .main-content h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 10px;
}


.overlay-payment .main-content > div {
  display: flex;
}

.overlay-payment #contact_infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 0 20px 20px;
}


.overlay-payment #contact_infos input {
  background-color: #E2E2E2;
  border: none;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 20px;
  padding: 10px;
  outline: none;
  margin: 10px;
  font-family: 'Cabin', sans-serif;
  transition: all 0.2s;
  width: 100%;
}


.overlay-payment #recap-achat {
  width: 40%;
  margin: 0 auto;
}


.overlay-payment #recap-achat h3 {
  font-size: 26px;
  margin-bottom: 15px;
}

#recap-divider {
  width: 80%;
  height: 2px;
  margin: 15px auto;
  background-color: #EFAB45;
}


.overlay-payment #recap-achat p {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin: 5px 0;
}


#total {
  font-size: 36px!important;
  margin-bottom: 20px!important;
  font-weight: bold;
}

#paid {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 100px 0;
}

#paid img {
  margin: 0 auto 20px;
}

#paid p {
  text-align: center;
  font-size: 24px;
  max-width: 80%;
  margin: 10px auto 0;
}

/* payment box */
#payment-form {
  max-width: 100%;
  min-width: 250px;
  align-self: center;
  margin: 10px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #EFAB45;
  font-family: "Cabin", sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #EFAB45;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #EFAB45;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 80vw;
    min-width: initial;
  }
}















@media screen and (max-width: 767px) {
  nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
  }

  nav img {
    height: 50px;
  }

  nav ul {
    display: none;
  }

  #menu-icon {
    display: block;
    margin-top: 15px;
    margin-right: 25px;
    height: 40px;
    cursor: pointer;
  }

  #menu-xs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #F0F0F0;
  }

  #menu-xs > ul {
    margin-top: 80px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 30px;
  }

  #menu-xs > ul ul {
    margin-left: 30px;
    font-size: 24px;
    font-weight: normal;
  }

  #menu-xs a {
    text-decoration: none;
  }

  #menu-xs {
    font-size: 26px;
  }












  #homepage_right img {
    display: none;
  }

  #homepage_left {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F0F0F0;
    padding: 0 20px;
    border-bottom-right-radius: 80%;
    border-top-right-radius: 25%;
    margin-right: 5px;
  }

  #homepage h1 {
    font-size: 36px;
  }

  #homepage h2 {
    font-size: 24px;
  }


  /* Presentation */
  #presentation {
    padding: 20px 0;
  }

  #main_presentation {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  #main_presentation h3 {
    text-align: center;
    font-size: 26px;
  }

  #main_presentation p {
    font-size: 16px;
  }
  
  #main_presentation img {
    max-width: none;
    width: 100%;
    margin: 0;
  }

  /* compétitions */
  #competition_list {
    justify-content: space-around;
    padding: 0 10px;
  }

  .competition_list_item {
    width: 45%;
    margin: 0 0 15px 0;
  }

  .competition_list_item:first-child img, .competition_list_item:last-child img {
    height: 300px;
    margin-top: 0px;
  }

  #competitions_presentation h3 {
    font-size: 26px;
  }

  /* services */
  #services h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  #services h4 {
    font-size: 22px;
    max-width: 100%;
  }

  .services_list_item {
    width: 100%;
    height: auto;
    padding: 0 20px;
    margin: 10px 0;
  }

  .services_list_item img:first-child {
    width: 75px;
    height: 75px;
    margin: 15px 10px 15px 0;
  }

  .services_list_item img:last-child {
    width: 25px;
    height: 25px;
  }

  .services_list_item h5 {
    font-size: 18px;  
  }

  .services_list_item p {
    font-size: 14px
  }

  /* photos */
  #photos {
    padding: 20px 0;
  }
  
  #photos img {
    width: 40%;
    height: 150px;
    margin: 10px;
  }

  /* avis */
  #avis_liste .avis_item:last-child, #avis_liste .avis_item:nth-child(3) {
    display: none;
  }

  #avis_liste {
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
  }

  .avis_item {
    width: 80%;
  }

  #avis h3, #contact h3 {
    font-size: 28px;
  }


  /* contact */
  #contact_infos {
    flex-direction: column;
  }

  #contact_infos input {
    width: 100%;
    width: -webkit-fill-available;
    width: moz-available;
  }

  #button, #contact_infos input, #contact_infos textarea {
    font-size: 16px;
  }


  /* services */
  .main-content {
    width: 80%;
    margin: 100px 0;
  }

  .main-content-header h2 {
    text-align: center;
  }
 
  .main-content-body__row {
    flex-direction: column;
  }

  .main-content-body__row > div {
    width: 100%;
  }

  .disable-on-lg {
    display: block;
  }


  /* tarifs */ 
  #payment-form {
    width: 95%;
  }

  .overlay-payment .main-content {
    width: 90%;
    margin-top: 80px;
  }

  .overlay-tarifs h2 {
    margin: 80px 0 10px;
    text-align: center;
  }
  
  .tarifs-list {
    flex-wrap: wrap;
    margin-bottom: 100px;
    margin-top: 80px;
  }

  .tarif-box {
    width: 100%;
    margin: 0 10px 20px;
  }

  /* paiement */

  .overlay-payment .main-content > div {
    flex-direction: column;
    justify-content: center;
  }

  .overlay-payment #contact_infos, .overlay-payment #recap-achat {
    width: 90%;
    margin: auto;
  }

  .overlay-payment #recap-achat h3 {
    margin-top: 15px;
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  #homepage_right img {
    width: 70%;
  }

  /* services */
  .services_list_item {
    width: 40%;
    height: auto;
    padding: 0 20px;
    margin: 10px;
  }

  .services_list_item img:first-child {
    width: 75px;
    height: 75px;
  }

  .services_list_item img:last-child {
    width: 25px;
    height: 25px;
  }

  .services_list_item h5 {
    font-size: 18px;  
  }

  .services_list_item p {
    font-size: 14px
  }


  /* avis */
  #avis_liste .avis_item:last-child {
    display: none;
  }

  .avis_item {
    width: 30%;
  }
  

  /* tarifs */
  .overlay-tarifs h2 {
    margin: 100px 0 10px;
  }
  
  .tarifs-list {
    flex-wrap: wrap;
  }

  .tarif-box {
    width: 45%;
    margin: 0 10px 20px;
  }

  .overlay-payment .main-content > div {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .overlay-payment #contact_infos, .overlay-payment #recap-achat {
    width: 90%;
    margin: auto;
  }

  .overlay-payment #recap-achat h3 {
    margin-top: 15px;
  }

}