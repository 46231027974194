
#login-main-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login-button {
  width: min-content;
  margin-top: 5px;
}

.input-login {
  font-size: 18px;
  font-family: "Cabin";
  margin: 5px 0;
  border: 1px solid #EFAB45;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}